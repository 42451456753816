var arr = [
  {
    title: 'الصفحة الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
    per: "home"
  },
  {
    title: 'استعراض الحجوزات',
    route: 'orders',
    icon: 'CalendarIcon',
    per: "orders"
  },
  {
    title: 'المستحقات',
    route: 'stats',
    icon: 'DollarSignIcon',
    per: "stats"
  },
  {
    title: 'ارشيف الحسابات',
    route: 'archive',
    icon: 'ArchiveIcon',
    per: "archive"
  },
  {
    title: 'تسجيل الخروج',
    route: 'logout',
    icon: 'LogOutIcon'
  },
], arr2 = [], user = JSON.parse(localStorage.getItem("user"));

arr.forEach(function (e) {

  if(user.pers){
    if(user.pers.includes("*") || user.pers.includes(e.per) || !e.per){
      arr2.push(e)
    }
  }else{
    arr2.push(e)
  }

})

export default arr2
